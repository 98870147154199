import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { IFieldProps } from '.';
import { cvpartnerRegistry, defaultRegistry, tripletexRegistry } from './registries';

export type IFieldEntry = {
	key: string;
	type: string;
	label: string;
	description: string;
	categories: string[];
	icon: IconDefinition;
	component?(props: IFieldProps): JSX.Element; // REMOVE OPTIONAL
	parse?(value: any): string | number; // REMOVE OPTIONAL
};

export const FIELD_CATEGORIES = ['General', 'Library', '_divider_', 'CV Partner', 'Tripletex'];

export const fieldsRegistry: IFieldEntry[] = [...defaultRegistry, ...cvpartnerRegistry, ...tripletexRegistry];
