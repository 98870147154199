import { PhoneInput } from '@/shared/components/fields';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { IFieldProps } from '../..';

export const PhoneField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const parsedValue = parsePhoneField(value);

	if (readOnly) {
		return <InfoItem title={field.label} description={parsedValue} />;
	}

	return <PhoneInput {...field} value={parsedValue} onChange={(value) => onChange(value)} />;
};

export const parsePhoneField = (value: string) => {
	if (typeof value !== 'string' || typeof value !== 'number') return 'Invalid value';
	return value;
};
