import { GenericDatePicker } from '@/shared/components/fields';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { isValidDate } from '@/utils/helpers';
import dayjs, { Dayjs } from 'dayjs';
import { IFieldProps } from '../..';

export const DateField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const parsedDate = parseDateField(value);
	const description = value ? dayjs(value).format(`DD MMM YYYY ${field?.showtime ? 'HH:mm' : ''}`) : 'No date selected';

	if (readOnly) {
		return <InfoItem title={field.label} description={description} />;
	}

	return (
		<GenericDatePicker
			{...field}
			className="field-input"
			value={parsedDate === 'Invalid value' ? null : parsedDate}
			onChange={(date: Dayjs) => onChange(date?.toISOString())}
		/>
	);
};

export const parseDateField = (value: string | Date) => {
	if (Array.isArray(value) && (isValidDate(value?.[0]) || isValidDate(value?.[0]?.[0]))) {
		return Array.isArray(value[0]) ? value[0][0] : value[0];
	}
	if (typeof value !== 'string' && value instanceof Date) {
		return 'Invalid value';
	}
	return value;
};
