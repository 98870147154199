import { TextArea } from '@/shared/components/fields';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { useEffect, useRef } from 'react';
import { IFieldProps } from '../..';

export const TextAreaField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const hasSetDefault = useRef<boolean>(false);
	const parsedValue = parseTextAreaField(value);

	useEffect(() => {
		if (!value && !!field?.default_content && !hasSetDefault.current) {
			hasSetDefault.current = true;
			onChange(field.default_content);
		}
	}, [value, field]);

	if (readOnly) {
		return <InfoItem title={field.label} description={<TextArea value={parsedValue} label={''} readOnly />} />;
	}
	return <TextArea {...field} value={parsedValue} onChange={(e) => onChange(e.target.value)} readOnly={readOnly} />;
};

export const parseTextAreaField = (value: string) => {
	if (typeof value !== 'string' && value !== null && value !== undefined) {
		return 'Invalid value';
	}
	return value;
};
