import { IOption } from '@/features/datasources/types';
import { RenderSelectionDot } from '@/features/templates/components/RenderSelectionDot';
import { DropdownGeneric } from '@/shared/components/fields';
import { DropdownItem } from '@/shared/components/items/DropdownItem';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { fetcher } from '@/utils/api';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSWR from 'swr';
import { IFieldProps } from '../..';

export const SelectField = ({ value, onChange, field, readOnly }: IFieldProps<string>) => {
	const { data: options } = useSWR<IOption[]>(field?.uid ? `/datasources/options?field=${field.uid}` : null, fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	});

	const selected = options?.find((o) => o.uid === value);

	const buttonText = (
		<div className="flex items-center gap-1 text-xs">
			{!!selected ? (
				<>
					<RenderSelectionDot color={selected.color} />
					{selected.text}
				</>
			) : readOnly ? (
				'Not defined'
			) : (
				field.placeholder
			)}
		</div>
	);

	if (readOnly) {
		return <InfoItem title={field.label} description={buttonText} />;
	}

	return (
		<div>
			<p className="text-xs font-medium text-gray-500">{field.label}</p>
			<DropdownGeneric
				collection={field?.datasource?.options}
				renderItem={({ item }: { item: IOption }) => {
					const option = field?.datasource?.options?.find((o) => o.uid === item.uid);
					return (
						<DropdownItem
							key={item.uid}
							title={
								<>
									<RenderSelectionDot color={option?.color} selected={item.uid === value} /> {item.text}
								</>
							}
							selected={item.uid === value}
							onClick={() => onChange(item.uid)}
						/>
					);
				}}
				button={
					<div className="field-style flex items-center justify-between gap-4 border-b-2">
						<p className={`${!!selected ? '' : 'text-gray-400'} text-sm`}>{buttonText}</p>
						<FontAwesomeIcon icon={faChevronDown} />
					</div>
				}
			/>
		</div>
	);
};

export const parseSelectField = (value: IOption) => {
	return value?.text ?? 'Invalid value';
};
