import { InfoItem } from '@/shared/components/items/InfoItem';
import { Switch } from 'antd';
import React from 'react';
import { IFieldProps } from '../..';

export const ToggleField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const key = React.useId();
	const parsedValue = parseToggleField(value);

	if (readOnly) {
		return <InfoItem title={field.label} description={parsedValue} />;
	}

	return (
		<div className="space-x-2">
			<Switch
				key={key}
				checked={value}
				onChange={(checked) => onChange(checked)}
				style={{ backgroundColor: value ? 'var(--color-blue-500)' : 'rgba(0, 0, 0, 0.5)' }}
			/>
			<label className="text-sm text-gray-500" htmlFor={key}>
				{field.label}
			</label>
		</div>
	);
};

export const parseToggleField = (value: boolean) => {
	return !!value ? 'Yes' : 'No';
};
