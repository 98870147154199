import { fetcher } from '../../../utils/api';
import { IPrice } from '../../candidateflow/types';

export const getPrices = async (talent: string): Promise<IPrice[]> => {
	if (!talent) return [];
	try {
		return await fetcher(`/talents/${talent}/prices`);
	} catch (error) {
		console.error('Processing talent (' + talent + ' prices failed');
	}
};
