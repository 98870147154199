import { MoneyInput, formatValue } from '@/shared/components/inputs';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { formatTypeValues } from '@/shared/utils/helper';
import { z } from 'zod';
import { IFieldProps } from '../..';

const valueSchema = z.object({
	value: z.number(),
	currency: z.string(),
	locale: z.string(),
});

export const MoneyField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const parsedValue = parseMoneyField(value);

	if (readOnly) {
		return (
			<InfoItem
				title={field.label}
				description={typeof parsedValue === 'string' ? parsedValue : formatValue(formatTypeValues(parsedValue, field.type)) ?? 'Not defined'}
			/>
		);
	}
	return <MoneyInput {...field} className="field-input" value={formatTypeValues(parsedValue, field.type)} onChange={(value) => onChange(value)} />;
};

export const parseMoneyField = (value: any): { value: number; locale: string; currency: string } | string => {
	const check = valueSchema.safeParse(value);
	if (!check.success) {
		return 'Invalid value';
	}
	return value;
};
