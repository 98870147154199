import { NumberInput } from '@/shared/components/inputs';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { IFieldProps } from '../..';

export const NumberField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const parsedValue = parseNumberField(value);

	if (readOnly) {
		return <InfoItem title={field.label} description={String(parsedValue)} />;
	}

	return <NumberInput {...field} value={parsedValue} onChange={(value) => onChange(value)} />;
};

export const parseNumberField = (value: number | string) => {
	if (isNaN(Number(value)) || typeof value !== 'number') {
		return 0;
	}
	return Number(value);
};
