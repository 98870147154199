import { IFieldProps } from '@/features/fields';
import { DropdownGeneric } from '@/shared/components/fields';
import { DropdownItem } from '@/shared/components/items/DropdownItem';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { getTripletexResource } from '@/utils/tripletex';
import { faCheck, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';

export const TripletexParticipants = ({ onChange, ...props }: IFieldProps) => {
	const parsedParticipants = parseParticipants(props.value);
	const [employees, setEmployees] = useState<any[]>([]);
	const [participants, setParticipants] = useState<any[]>([]);
	const participantsRef = useRef<any[]>([]);

	useEffect(() => {
		fetchEmployees();
	}, []);

	useEffect(() => {
		fetchParticipants();
	}, [employees]);

	const fetchParticipants = async () => {
		if (!employees.length || !parsedParticipants.length) return;
		const participants = await Promise.all(
			parsedParticipants.map(async (p) => {
				const data = await getTripletexResource(`project-participant-${p.id}`);
				const employee = employees.find((e) => e.id === data.value.employee.id);
				return { ...data.value, employee };
			})
		);
		participantsRef.current = participants;
		setParticipants(participants);
	};

	const fetchEmployees = async () => {
		const data = await getTripletexResource('employee');
		setEmployees(data.values);
	};

	const toggleParticipant = (id: string) => {
		const index = participants.findIndex((p) => p.id === id);
		if (index > -1) {
			setParticipants((prev) => prev.filter((p) => p.id !== id));
			const newValue = parsedParticipants.filter((p) => p.id !== id);
			onChange(newValue);
		} else {
			const participant = participantsRef.current.find((p) => p.id === id);
			setParticipants((prev) => [...prev, participant]);
			const newValue = [...parsedParticipants, { id: participant.id, url: `api.tripletex.io/v2/project/participant/${participant.id}` }];
			onChange(newValue);
		}
	};

	const getText = () => {
		if (participants.length === 0) return props?.field?.placeholder ?? 'Select participants';
		if (participants.length === 1) return participants[0].employee.displayname;
		return `${participants[0].employee.displayname} +${participants.length - 1}`;
	};

	const getParticipantDisplayNames = () => {
		return participants
			.map((p) => {
				return p.employee.displayname;
			})
			.join('\n');
	};

	if (props.readOnly) {
		return <InfoItem title={props?.field?.label} description={getParticipantDisplayNames()} />;
	}

	return (
		<div>
			<p className="text-xs font-medium text-gray-400">{props.field.label}</p>
			<DropdownGeneric
				collection={participantsRef.current}
				renderItem={({ item }) => (
					<DropdownItem
						title={item.employee.displayname}
						selected={participants.some?.((p) => p.employee.id === item.employee.id)}
						icon={faCheck}
						onClick={() => toggleParticipant(item.id)}
					/>
				)}
				button={
					<div className="field-style field-input flex cursor-pointer items-center justify-between gap-2">
						<p className="line-clamp-1">{getText()}</p>
						<FontAwesomeIcon icon={faChevronDown} />
					</div>
				}
			/>
		</div>
	);
};

export const parseParticipants = (value: any) => {
	if (!Array.isArray(value) && value?.some?.((p) => !p?.id || !p?.url)) {
		return [];
	}
	return value;
};
