import { IOption } from '@/features/datasources/types';
import { RenderSelectionDot } from '@/features/templates/components/RenderSelectionDot';
import { DropdownGeneric } from '@/shared/components/fields';
import { DropdownItem } from '@/shared/components/items/DropdownItem';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { fetcher } from '@/utils/api';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSWR from 'swr';
import { IFieldProps } from '../..';

export const MultiSelectField = ({ value, onChange, field, readOnly }: IFieldProps<string[]>) => {
	const parsedValue = parseMultiSelectField(value);
	const { data: options } = useSWR<IOption[]>(field?.uid ? `/datasources/options?field=${field.uid}` : null, fetcher, {
		revalidateOnFocus: false,
		revalidateIfStale: false,
		refreshInterval: 0,
	});

	let selectedOptions = [];

	if (parsedValue !== 'Invalid value') {
		value?.reduce((acc, uid) => {
			const option = options?.find((s) => s.uid === uid);
			if (!option) return acc;
			acc.push(
				<div key={field.uid + option.uid} className="flex items-center gap-1 text-xs">
					<>
						<RenderSelectionDot color={option?.color} />
						{option?.text}
					</>
				</div>
			);
			return acc;
		}, []);
	}

	if (readOnly) {
		const description = parsedValue === 'Invalid value' ? 'Invalid value' : selectedOptions;
		return <InfoItem title={field.label} description={description} />;
	}

	return (
		<div>
			<p className="text-xs font-medium text-gray-500">{field.label}</p>
			<DropdownGeneric
				collection={options}
				autoClose={false}
				renderItem={({ item }: { item: IOption }) => {
					const isSelected = value?.includes?.(item.uid);
					return (
						<DropdownItem
							key={item.uid}
							title={
								<>
									<RenderSelectionDot color={item.color} selected={isSelected} /> {item.text}
								</>
							}
							selected={isSelected}
							onClick={() => onChange(item.uid)}
						/>
					);
				}}
				button={
					<div className="field-style flex items-center justify-between gap-4 border-b-2">
						<p className={`${selectedOptions?.length > 0 ? '' : 'text-gray-400'} text-sm`}>
							{selectedOptions?.length > 0 ? selectedOptions : field.placeholder}
						</p>
						<FontAwesomeIcon icon={faChevronDown} />
					</div>
				}
			/>
		</div>
	);
};

export const parseMultiSelectField = (value: any) => {
	if (!Array.isArray(value) || value?.every((v) => !!v?.text && !!v?.uid)) {
		return 'Invalid value';
	}
	return value;
};
