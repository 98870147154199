import { fetcher } from '../api';

/** CUSTOMERS */

export const updateCustomer = async (id: number, customer: Record<string, any>) => {
	return await fetcher(`/tripletex/customers/${id}`, {
		method: 'PUT',
		body: JSON.stringify(customer),
	});
};

export const exportCustomerToTripletex = async (customer: Record<string, any>) => {
	return await await fetcher('/tripletex/customers', {
		method: 'POST',
		body: JSON.stringify(customer),
	});
};

export const importCustomersFromTripletex = async () => {
	return await fetcher('/tripletex/customers/import', {
		method: 'POST',
	});
};

/** EMPLOYEES */

export const syncEmployeesFromTripletex = async () => {
	return await fetcher('/tripletex/employees/sync', {
		method: 'POST',
	});
};

/** PROJECTS */

export const importProjectsFromTripletex = async (template: string) => {
	return await fetcher(`/tripletex/projects/import`, {
		method: 'POST',
		body: JSON.stringify({ template }),
	});
};

export const getProjectHourlyRates = async (projectId: string) => {
	return await fetcher(`/tripletex/projects/${projectId}/hourlyrates`);
};

/** RESOURCES */

export const getTripletexResource = async (resource: string) => {
	return await fetcher(`/tripletex/resources/${resource}`);
};
