import { IFieldProps } from '@/features/fields';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/shared/components/ui/dropdown';
import { DropdownMenu } from '@/shared/components/ui/dropdown-menu';
import { getTripletexResource } from '@/utils/tripletex';
import { faCheck, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

const RESOURCE_URL = 'api.tripletex.io/v2/{resource}/{id}';

const COLLECTION_MAP = {
	tripletex_customer: 'customer',
	tripletex_project_manager: 'employee',
	tripletex_delivery_address: 'deliveryAddress',
	tripletex_department: 'department',
	tripletex_main_project: 'project',
	tripletex_project_category: 'project-category',
	tripletex_vat_type: 'vatType',
	tripletex_currency: 'currency',
	tripletex_contact: 'contact',
	tripletex_attention: 'contact',
};

const NAME_MAP = {
	tripletex_customer: 'name',
	tripletex_project_manager: 'displayname',
	tripletex_delivery_address: 'displayname',
	tripletex_department: 'name',
	tripletex_main_project: 'name',
	tripletex_vat_type: 'displayname',
	tripletex_currency: 'displayname',
	tripletex_contact: 'displayname',
	tripletex_attention: 'displayname',
};

const PLACEHOLDER_MAP = {
	tripletex_customer: 'Select customer',
	tripletex_project_manager: 'Select project manager',
	tripletex_project_category: 'Select project category',
	tripletex_delivery_address: 'Select address',
	tripletex_department: 'Select department',
	tripletex_main_project: 'Select main project',
	tripletex_vat_type: 'Select vat type',
	tripletex_currency: 'Select currency',
	tripletex_contact: 'Select contact',
	tripletex_attention: 'Select attention',
};

export const TripletexResourceField = ({ onChange, ...props }: IFieldProps) => {
	const parsedValue = parseResouceField(props.value);
	const [resources, setResources] = useState<any[]>([]);
	const selectedResource = resources.find((e) => e.id === parsedValue?.id);
	const displayName = selectedResource?.[NAME_MAP[props.field.type]] ?? 'Not defined';

	useEffect(() => {
		fetchResources();
	}, []);

	const fetchResources = async () => {
		const data = await getTripletexResource(COLLECTION_MAP[props.field.type]);
		if (data?.values) {
			setResources(data.values);
		}
	};

	if (props.readOnly) {
		return <InfoItem title={props?.field?.label} description={displayName} />;
	}

	return (
		<div>
			{props?.field?.label && (
				<p className="text-xs font-medium text-gray-500 transition-all duration-300 group-focus-within:text-blue-500">{props.field.label}</p>
			)}
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<div className="field-style field-input flex w-full cursor-pointer items-center justify-between">
						{selectedResource ? displayName : props?.field?.placeholder ?? PLACEHOLDER_MAP[props.field.type]}
						<FontAwesomeIcon icon={faChevronDown} />
					</div>
				</DropdownMenuTrigger>
				<DropdownMenuContent className="dropdown-trigger-width">
					{resources.map((item) => (
						<DropdownMenuItem
							key={item?.id}
							className="flex items-center justify-between"
							onClick={() => {
								onChange({
									id: item.id,
									url: RESOURCE_URL.replace('{resource}', COLLECTION_MAP[props.field.type]).replace('{id}', item.id),
								});
							}}
						>
							{item[NAME_MAP[props.field.type]]}
							{item.id === selectedResource?.id && <FontAwesomeIcon icon={faCheck} />}
						</DropdownMenuItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
};

export const parseResouceField = (value: any) => {
	if (!value?.id || !value?.url) {
		return null;
	}
	return value;
};
