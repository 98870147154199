import { BasicButton } from '@/components/buttons/BasicButton';
import { IField } from '@/features/fields/types';
import { fieldRegex, parseName } from '@/utils/helpers';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GenericCollapse } from '../GenericCollapse';
import { TextEditor } from '../TextEditor';
import { DropdownItem } from '../items/DropdownItem';
import { DropdownGeneric } from './DropdownGeneric';
import { TextArea } from './TextArea';

interface IPartyField {
	value: {
		user: string;
		content: string;
	};
	entry: any;
	field: IField;
	readOnly?: boolean;
	onChange?(value: { user: string; content: string }): void;
}

export const PARTY_FIELDS: IField[] = [
	{
		label: 'Firstname',
		key: 'firstname',
		type: 'text',
		required: false,
	},
	{
		label: 'Lastname',
		key: 'lastname',
		type: 'text',
		required: false,
	},
	{
		label: 'Email',
		key: 'email',
		type: 'text',
		required: false,
	},
	{
		label: 'Phone no.',
		key: 'phone',
		type: 'text',
		required: false,
	},
	{
		label: 'Title',
		key: 'title',
		type: 'text',
		required: false,
	},
	{
		label: 'Company name',
		key: 'space',
		type: 'text',
		required: false,
	},
	{
		label: 'VAT no.',
		key: 'vat',
		type: 'text',
		required: false,
	},
];

export const PartyField = ({ value, entry: contract, field, readOnly, onChange = () => void 0 }: IPartyField) => {
	let user = value?.user;
	let content = value?.content;
	const party = contract?.parties?.find((p) => p.space_uid === user);

	if (user === undefined) {
		const filteredTypes = contract?.parties?.filter((p) => p.type === field?.party?.type);
		const defaultParty = filteredTypes?.[field?.party?.index] ?? filteredTypes?.[0];
		onChange({ ...(value ?? { content: undefined }), user: defaultParty?.space_uid ?? '' });
		return;
	}
	if (content === undefined) {
		content = field?.default_content ?? '';
		onChange({ ...(value ?? { user: undefined }), content });
	}

	if (readOnly) {
		const parsedContent = content?.replace(fieldRegex, (match, key) => {
			return party?.[key] ?? '';
		});
		return <TextArea value={parsedContent} readOnly />;
	}

	return (
		<div className="space-y-2">
			<p className="mb-1 text-xs font-medium text-gray-500">{field.label}</p>
			<DropdownGeneric
				collection={contract?.parties ?? []}
				renderItem={({ item }: any) => (
					<DropdownItem
						key={item.uid}
						title={parseName(item)}
						onClick={() => onChange({ ...(value ?? { content: '' }), user: item.space_uid })}
						selected={party?.uid === item.uid}
					/>
				)}
				button={<BasicButton className="w-full">{party ? parseName(party) : 'Choose a party'}</BasicButton>}
			/>
			<GenericCollapse
				defaultOpen={true}
				renderButton={({ open }) => (
					<div className="my-2 flex w-full items-center justify-between">
						<hr className="w-4 bg-gray-400" />
						<div className="flex flex-shrink select-none gap-2 px-2 text-xs font-medium text-gray-400">
							<span className="font-medium">Content</span>
						</div>
						<hr className="flex-1 bg-gray-400 " />
						<FontAwesomeIcon className={`select-none px-2 text-xs text-gray-400 ${!open ? 'rotate-180' : ''}`} icon={faChevronDown} />
						<hr className="w-4 bg-gray-400" />
					</div>
				)}
			>
				<TextEditor value={content} onEditorChange={(content) => onChange({ ...(value ?? { user: '' }), content })} fields={PARTY_FIELDS} />
			</GenericCollapse>
		</div>
	);
};
