import { PriceSelector } from '@/shared/components/fields/PriceSelector';
import { formatValue } from '@/shared/components/inputs';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { IFieldProps } from '../..';

export const PriceSelectorField = ({ value, onChange, field, task, readOnly }: IFieldProps) => {
	const parsedValue = parsePriceSelectorField(value);

	if (readOnly) {
		const { price, currency, locale, tags } = parsedValue;
		const tag = Array.isArray(tags) ? tags?.[0] : Object.values(tags ?? {})?.[0];

		return (
			<InfoItem
				title={field.label}
				description={
					<div className="flex items-center gap-2">
						{tag && (
							<div
								key={tag.uid}
								style={{ backgroundColor: tag.background_color }}
								className="grid w-full place-content-center rounded-lg py-1"
							>
								<p style={{ color: tag.text_color }} className="px-2 text-center text-[11px] font-medium capitalize">
									{tag.name}
								</p>
							</div>
						)}
						<p className="text-xs">{formatValue({ value: price, currency, locale })}</p>
					</div>
				}
			/>
		);
	}

	return (
		<div>
			<p className="mb-1 text-xs font-medium text-gray-500">{field.label}</p>
			<PriceSelector value={value} onChange={(price) => onChange(price)} task={task} />
		</div>
	);
};

export const parsePriceSelectorField = (value: any) => {
	if (!value?.price || !value?.currency || !value?.locale) {
		return {
			price: 0,
			currency: 'DKK',
			locale: 'da',
			tags: [],
		};
	}
	return value;
};
