import { TextInput } from '@/shared/components/fields';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { Popover } from 'antd';
import { IFieldProps } from '../..';

export const TextField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const parsedValue = parseTextField(value);

	if (readOnly) {
		return <InfoItem title={field.label} description={parsedValue} />;
	}
	return <TextInput {...field} value={value} onChange={(e) => onChange(e.target.value)} className="overflow-hidden text-ellipsis" />;
};

export const TextFieldTable = ({ value }: { value: string }) => {
	return (
		<div>
			<Popover content={value} overlayClassName="custom-popover" placement="top">
				<div className="max-w-[10rem] truncate">{value}</div>
			</Popover>
		</div>
	);
};

export const parseTextField = (value: any) => {
	if (typeof value !== 'string' && value !== null && value !== undefined) {
		return 'Invalid value';
	}
	return value;
};
