import { InfoItem } from '@/shared/components/items/InfoItem';
import { Slider } from 'antd';
import { IFieldProps } from '../..';

export const SliderField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const parsedValue = parseSliderField(value);

	if (readOnly) {
		return <InfoItem title={field.label} description={parsedValue} />;
	}

	return (
		<div>
			<p className="mb-1 text-xs font-medium text-gray-500">{field.label}</p>
			<Slider
				className="mx-4"
				value={value ?? 0}
				onChange={onChange}
				step={5}
				tooltip={{ formatter: (value) => `${value}%` }}
				trackStyle={{ backgroundColor: 'var(--color-blue-500)' }}
			/>
		</div>
	);
};

export const parseSliderField = (value: number | string) => {
	if (!value || typeof value !== 'number' || isNaN(Number(value))) {
		return 'Invalid value';
	}
	return `${value}%`;
};
