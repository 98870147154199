import { PricingCard } from '@/components/projects/components/pricing/PricingCard';
import { IPrice } from '@/features/candidateflow/types';
import { getTagsBySources } from '@/features/tags/utils/getTagsBySources';
import { useEffect, useState } from 'react';
import { getPricesForTask } from '../../../features/pricings/utils/api';

interface IPriceSelector {
	value: IPrice;
	onChange(price: IPrice): void;
	task: string;
}

export const PriceSelector = ({ value, onChange, task }: IPriceSelector) => {
	const [pricings, setPricings] = useState([]);
	useEffect(() => {
		fetchPricings();
	}, [task]);

	const fetchPricings = async () => {
		const pricings: IPrice[] = await getPricesForTask(task);
		const tags = await getTagsBySources(pricings.map((v) => v.uid));
		setPricings(pricings.map((price) => ({ ...price, tags: tags[price.uid] })));
		onChange(pricings[0]);
	};

	return (
		<div className="mt-4 grid grid-cols-1 gap-4">
			{pricings?.map((p) => <PricingCard key={p.uid} price={p} selectedPrice={value} onChange={onChange} />)}
		</div>
	);
};
