import { fetcher } from '../../../utils/api';

export const createBasePrice = async ({ price, currency, tags }) => {
	return await fetcher('/pricings', {
		method: 'POST',
		body: JSON.stringify({
			price,
			currency,
			tags,
		}),
	});
};

export const getPriceConditions = async () => {
	return ((await fetcher(`/pricings/conditions`)) || []).map((c) => ({ priority: c.priority || 0, ...c }));
};

export const addPricesToTask = async ({ uid, prices }) => {
	return await fetcher(`/tasks/${uid}/prices`, {
		method: 'PUT',
		body: JSON.stringify({
			prices,
		}),
	});
};

export const getPricesForTask = async (uid) => {
	return await fetcher(`/tasks/${uid}/prices`);
};

export type IPricesQuery = {
	talent: string;
	updated?: string;
	pricingKey?: string[];
	spaces: string[];
	customers: string[];
	start?: string;
	end?: string;
	tasks?: string[];
};

export const queryPrices = async (uid: string, query: IPricesQuery) => {
	const { talent, updated, pricingKey, spaces, customers, start, end, tasks } = query;
	return await fetcher(
		`/pricings/${uid}?talents=${JSON.stringify([talent])}&periods=${JSON.stringify(pricingKey)}&spaces=${JSON.stringify(
			spaces || []
		)}&customers=${JSON.stringify(customers || [])}&start=${start}&end=${end}&tasks=${JSON.stringify(tasks)}`
	);
};

export const getViewAsPrices = async (uid, space) => {
	return await fetcher(`/pricings/${uid}/summery?space=${space}`);
};

export const changeConditionPriority = async (uid: string, priority: number) => {
	return await fetcher(`/pricings/conditions/${uid}/priority`, {
		method: 'PUT',
		body: JSON.stringify({
			priority,
		}),
	});
};

export const addQueryToCondition = async (uid: string, query: string) => {
	return await fetcher(`/pricings/conditions/${uid}/query`, {
		method: 'PUT',
		body: JSON.stringify({
			query,
		}),
	});
};

export const createPriceCondition = async ({
	modifiers,
	query,
	priority,
}: {
	modifiers: {
		option: string;
		action: string;
		amount: number;
	}[];
	priority?: number;
	query?: string;
}) => {
	return await fetcher(`/pricings/conditions`, {
		method: 'POST',
		body: JSON.stringify({
			modifiers,
			query,
			priority,
		}),
	});
};

export const getBasePrices = async () => {
	return await fetcher('/pricings');
};

export const updateBasePrice = async (uid, { min, max, currency }: { min?: number; max?: number; currency?: string }) => {
	return await fetcher(`/pricings/${uid}`, {
		method: 'PUT',
		body: JSON.stringify({
			min_price: min,
			max_price: max,
			currency,
		}),
	});
};

export const deletePricing = async (uid: string) => {
	return await fetcher(`/pricings/${uid}`, {
		method: 'DELETE',
	});
};

export const deleteCondition = async (uid: string) => {
	return await fetcher(`/pricings/conditions/${uid}`, {
		method: 'DELETE',
	});
};

export const getCatalogues = async () => {
	return await fetcher('/pricings/catalogues');
};

export const getCataloguesPrices = async (catalogue) => {
	return await fetcher(`/pricings/catalogues/${catalogue}/prices`);
};

export const createCatalog = async ({ name, task }) => {
	return await fetcher('/pricings/catalogues', {
		method: 'POST',
		body: JSON.stringify({
			name,
			task,
		}),
	});
};

export const updateCatalogue = async (uid, { name, task }) => {
	return await fetcher(`/pricings/catalogues/${uid}`, {
		method: 'put',
		body: JSON.stringify({
			name,
			task,
		}),
	});
};

export const addPricesToCatalogue = async (catalogue: string, prices: string[]) => {
	return await fetcher(`/pricings/catalogues/${catalogue}/prices`, {
		method: 'PUT',
		body: JSON.stringify({
			prices,
		}),
	});
};
