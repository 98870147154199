import { spaceSettings } from '@/atoms/spaces';
import { useEffect, useRef, useState } from 'react';
import { atom, useRecoilValue } from 'recoil';
import { IPrice } from '../../../../features/candidateflow/types';
import { renderPrice, renderPricePill } from '../../../../features/pricings/components/Pill';
import { TagsViewer } from '../../../../features/tags/components/TagsViewer';
import { getPrices } from '../../../../features/talents/utils/api';
import { MoneyInput } from '../../../../shared/components/inputs';

interface IPricingCard {
	price: IPrice;
	selectedPrice: IPrice;
	onChange(price: IPrice): void;
}

export const selectedTalentPriceAtom = atom<{ talent: string; prices: string[] }>({
	key: 'selectedTalentPriceAtom',
	default: {
		talent: '',
		prices: [],
	},
});

export const PricingCard = ({ price, selectedPrice, onChange }: IPricingCard) => {
	const [input, setInput] = useState<IPrice>();
	const [error, setError] = useState<string>('');
	const _price = useRef<IPrice>(price);
	const space_settings = useRecoilValue(spaceSettings);
	const talentPrices = useRecoilValue(selectedTalentPriceAtom);
	const selectedTalentPrice = useRef(null);

	useEffect(() => {
		if (selectedPrice && price.uid === selectedPrice.uid) {
			setInput({ ...price, ...selectedPrice });
		} else {
			setInput(price);
		}
	}, [price, selectedPrice]);

	const fetchTalentPrices = async () => {
		const prices = await getPrices(talentPrices.talent);
		if (prices.length === 0) return;
		const selectedPrice: any = prices.find((p) => p.uid === price.uid);
		if (selectedPrice) {
			selectedTalentPrice.current = selectedPrice.price;
			handleChange({ value: selectedPrice.price });
		}
	};

	useEffect(() => {
		if (talentPrices.prices.includes(price.uid)) {
			fetchTalentPrices();
		}
	}, [talentPrices]);

	const handleChange = (e) => {
		const value = e.value;
		setError('');

		if (_price.current.type !== 'free_pricing') {
			if (Number(value) > _price.current.max_price) {
				setError('Price must not be higher than the maximum price');
				return;
			}

			if (Number(value) < _price.current.min_price) {
				setError('Price must be higher than the minimum price');
				return;
			}
		}

		const newPrice = {
			..._price.current,
			_modifications: {
				min_price: value - _price.current.min_price,
				max_price: value - _price.current.max_price,
				price: value,
			},
		};

		onChange(newPrice);
	};

	const onClick = () => {
		onChange(input);
	};

	if (!input) {
		return null;
	}

	const isSelected = selectedPrice?.uid === price.uid;

	return (
		<div
			onClick={onClick}
			className={`relative flex cursor-pointer flex-col justify-center gap-1 rounded-md border-2 p-2 ${
				isSelected ? 'border-blue-500' : 'border-gray-100 '
			}`}
		>
			<div
				className={`absolute -top-3.5 left-1 rounded-full bg-white bg-opacity-50 px-2 py-1 text-[12px] font-medium ${
					isSelected ? 'border-blue-500 text-blue-500' : 'border-gray-100 text-gray-400 '
				}`}
			>
				{renderPricePill(price)}
			</div>
			<div className={`flex gap-3 p-2 text-sm ${isSelected ? ' text-blue-500' : 'text-gray-400 '} w-fit rounded-md`}>
				{renderPrice({ ...price, space: space_settings })}
			</div>
			{price.tags && price.tags.length > 0 && (
				<p className="text-xs font-medium opacity-80">
					<TagsViewer tags={price.tags} shownAmount={9999} fullWidth={true} />
				</p>
			)}
			{isSelected && price.type !== 'fixed_pricing' && (
				<>
					<div className="">{!!error && <p className="text-xs text-red-500">{error}</p>}</div>
					<div className="items-center justify-between">
						<p className="text-xs font-medium">Your price:</p>
						<div className="mt-2 flex gap-1 border-b-2 pb-2 text-sm">
							<MoneyInput
								renderCurrencyContainer={false}
								value={{
									value: selectedTalentPrice.current !== null ? selectedTalentPrice.current : input.max_price,
									...space_settings,
									currency: price.currency.prefix,
								}}
								onChange={handleChange}
								disableCurrencySelection={true}
								className="rounded-md bg-gray-200 px-2 py-1"
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
