import { CONTINUATION, ExtendedTask } from '@/shared/components/fields/ExtendedTask';
import { InfoItem } from '@/shared/components/items/InfoItem';
import Link from 'next/link';
import { IFieldProps } from '../..';

export const ExtendedTaskField = ({ value, onChange, field, task, readOnly }: IFieldProps) => {
	const parsedValue = parseExtendedTaskField(value);

	const link = `/projects/${value?.extended_task_uid}`;
	const continuationType =
		parsedValue?.continuation_type === CONTINUATION[CONTINUATION.INTERNAL] ? 'Continuation of own consultant' : 'Continuation competitor';

	if (readOnly) {
		return (
			<InfoItem
				title={field.label}
				description={
					<div className="flex items-center gap-2 text-xs font-medium">
						<p>{continuationType} - </p>
						<Link className="text-blue-500 underline" href={link}>
							Task
						</Link>
					</div>
				}
			/>
		);
	}

	return (
		<ExtendedTask
			task={task}
			value={parsedValue === 'Invalid value' ? undefined : value}
			placeholder={field.placeholder}
			label={field.label}
			onChange={(value) => onChange(value)}
		/>
	);
};

export const parseExtendedTaskField = (value: any) => {
	if (value !== CONTINUATION[CONTINUATION.INTERNAL] || value !== CONTINUATION[CONTINUATION.EXTERNAL]) {
		return 'Invalid value';
	}
	return value;
};
