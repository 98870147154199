import { PriceRange } from '@/shared/components/fields';
import { formatValue } from '@/shared/components/inputs';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { formatTypeValues } from '@/shared/utils/helper';
import { IFieldProps } from '../..';

export const PriceRangeField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const parsedValue = parsePriceRangeField(value);
	const description = parsedValue.map((value) => formatValue(formatTypeValues(value, field.type))).join(' - ');

	if (readOnly) {
		return <InfoItem title={field.label} description={description} />;
	}

	return <PriceRange {...field} value={parsedValue} onChange={(value) => onChange(value)} />;
};

export const parsePriceRangeField = (value: { value: number; space?: Record<string, any>; locale?: string; label?: string }) => {
	if (!Array.isArray(value) || value?.every((v) => typeof v === 'number' || typeof v === 'string')) {
		return [0, 0];
	}
	return value;
};
