import { Transition } from "@headlessui/react"
import { useEffect, useState } from "react"

interface IGenericCollapse {
    renderButton({ open }: { open }): JSX.Element
    children: JSX.Element
    defaultOpen?: boolean
    disabled?: boolean
}

export const GenericCollapse = ({ renderButton, children, defaultOpen = false }: IGenericCollapse) => {
    const [show, setShow] = useState<boolean>(defaultOpen)

    useEffect(() => {
        setShow(defaultOpen)
    }, [defaultOpen])

    return (
        <div>
            <div onClick={() => setShow((prev) => !prev)} className="flex w-full justify-between cursor-pointer">
                {renderButton({ open: show })}
            </div>
            <Transition
                show={show}
                className="transition-all duration-500"
                enterFrom="transform scale-95 opacity-0 max-h-0 overflow-hidden"
                enterTo="transform scale-100 opacity-100 max-h-[1000px]"
                leaveFrom="transform scale-100 opacity-100 max-h-[1000px]"
                leaveTo="transform scale-95 opacity-0 max-h-0"
            >
                {children}
            </Transition>
        </div>
    )
}