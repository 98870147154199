import { Viewer as ReactPdfViewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { ToolbarSlot, defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Ring } from '@uiball/loaders';
import 'pdfjs-dist/build/pdf.worker.entry';

interface IPDFViewer {
	fileUrl: string;
}

export const PDFViewer = ({ fileUrl }: IPDFViewer) => {
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		sidebarTabs() {
			return [];
		},
		renderToolbar: (Toolbar) => {
			return (
				<Toolbar>
					{(props: ToolbarSlot) => {
						const {
							CurrentPageInput,
							Download,
							EnterFullScreen,
							GoToNextPage,
							GoToPreviousPage,
							NumberOfPages,
							Print,
							ShowSearchPopover,
							Zoom,
							ZoomIn,
							ZoomOut,
						} = props;
						return (
							<div className="flex justify-between w-full">
								<div className="flex">
									<ShowSearchPopover />
									<ZoomOut />
									<Zoom />
									<ZoomIn />
								</div>
								<div className="flex">
									<GoToPreviousPage />
									<CurrentPageInput /> / <NumberOfPages />
									<GoToNextPage />
								</div>
								<div className="flex justify-end">
									<EnterFullScreen />
									<Download />
									<Print />
								</div>
							</div>
						);
					}}
				</Toolbar>
			);
		},
	});

	return <ReactPdfViewer renderLoader={() => <Ring color="var(--color-blue-500)" />} plugins={[defaultLayoutPluginInstance]} fileUrl={fileUrl} />;
};
