import { PartyField } from '@/shared/components/fields/PartyField';
import {
	faCalendar,
	faChevronCircleDown,
	faClock,
	faHashtag,
	faList,
	faMoneyCheckDollar,
	faNoteSticky,
	faPartyHorn,
	faPhone,
	faRotate,
	faSlider,
	faSlidersSimple,
	faTags,
	faText,
	faToggleOff,
	faUserCrown,
} from '@fortawesome/pro-regular-svg-icons';
import {
	AgreementOwnerField,
	DateField,
	DateRangeField,
	ExtendedTaskField,
	FTEField,
	MoneyField,
	MultiDateRangeField,
	MultiSelectField,
	NumberField,
	PhoneField,
	PriceRangeField,
	PriceSelectorField,
	SelectField,
	SliderField,
	TagsField,
	TextAreaField,
	TextField,
	ToggleField,
	parseAgreementOwnerField,
	parseDateField,
	parseDateRangeField,
	parseExtendedTaskField,
	parseFTEField,
	parseMoneyField,
	parseMultiDateRangeField,
	parseMultiSelectField,
	parseNumberField,
	parsePhoneField,
	parsePriceRangeField,
	parsePriceSelectorField,
	parseSelectField,
	parseSliderField,
	parseTagsField,
	parseTextAreaField,
	parseTextField,
	parseToggleField,
} from '../components/fields';
import { IFieldEntry } from '../registry';

export const defaultRegistry: IFieldEntry[] = [
	{
		key: 'text',
		type: 'text',
		label: 'Text',
		description: 'A single-line text input field.',
		categories: ['General', 'Text'],
		icon: faText,
		component: TextField,
		parse: parseTextField,
	},
	{
		key: 'textarea',
		type: 'textarea',
		label: 'Text Area',
		description: 'A multi-line text input field.',
		categories: ['General', 'Text'],
		icon: faNoteSticky,
		component: TextAreaField,
		parse: parseTextAreaField,
	},
	{
		key: 'number',
		type: 'number',
		label: 'Number',
		description: 'A single number input field.',
		categories: ['General', 'Number'],
		icon: faHashtag,
		component: NumberField,
		parse: parseNumberField,
	},
	{
		key: 'date',
		type: 'date',
		label: 'Date',
		description: 'A a single date input field.',
		categories: ['General', 'Date'],
		icon: faCalendar,
		component: DateField,
		parse: parseDateField,
	},
	{
		key: 'date_range',
		type: 'date_range',
		label: 'Date range',
		description: 'A range between two dates.',
		categories: ['General', 'Date'],
		icon: faCalendar,
		component: DateRangeField,
		parse: parseDateRangeField,
	},
	{
		key: 'multi_date_range',
		type: 'multi_date_range',
		label: 'Multi date range',
		description: 'A multi date range.',
		categories: ['General', 'Date'],
		icon: faCalendar,
		component: MultiDateRangeField,
		parse: parseMultiDateRangeField,
	},
	{
		key: 'money',
		type: 'money',
		label: 'Money input',
		description: 'A number money input with currency selector.',
		categories: ['General', 'Pricings', 'Number'],
		icon: faChevronCircleDown,
		component: MoneyField,
		parse: parseMoneyField,
	},
	{
		key: 'select',
		type: 'select',
		label: 'Select',
		description: 'A dropdown field with a single value.',
		categories: ['General', 'Select'],
		icon: faList,
		component: SelectField,
		parse: parseSelectField,
	},
	{
		key: 'multi_select',
		type: 'multiselect',
		label: 'Multi select',
		description: 'A dropdown field with multiple values.',
		categories: ['General', 'Select'],
		icon: faList,
		component: MultiSelectField,
		parse: parseMultiSelectField,
	},
	{
		key: 'phone',
		type: 'phone',
		label: 'Phone no.',
		description: 'A phone no. input field.',
		categories: ['General', 'Misc', 'Text'],
		icon: faPhone,
		component: PhoneField,
		parse: parsePhoneField,
	},
	{
		key: 'price_selector',
		type: 'price_selector',
		label: 'Price cards',
		description: 'Show pricings as selectable cards.',
		categories: ['General', 'Pricings'],
		icon: faMoneyCheckDollar,
		component: PriceSelectorField,
		parse: parsePriceSelectorField,
	},
	{
		key: 'price_range',
		type: 'price_range',
		label: 'Price range',
		description: 'A range between to money inputs.',
		categories: ['General', 'Pricings', 'Number'],
		icon: faSlidersSimple,
		component: PriceRangeField,
		parse: parsePriceRangeField,
	},
	{
		key: 'agreement_owner',
		type: 'agreement_owner',
		label: 'Agreement owner',
		description: 'Company who owns the task',
		categories: ['General', 'Misc'],
		icon: faUserCrown,
		component: AgreementOwnerField,
		parse: parseAgreementOwnerField,
	},
	{
		key: 'fte',
		type: 'fte',
		label: 'FTE',
		description: 'Calculate number of full-time employees.',
		categories: ['General', 'Misc', 'Text'],
		icon: faClock,
		component: FTEField,
		parse: parseFTEField,
	},
	{
		key: 'extended_task',
		type: 'extended_task',
		label: 'Extended task',
		description: 'Show if task is extended',
		categories: ['General', 'Date'],
		icon: faRotate,
		component: ExtendedTaskField,
		parse: parseExtendedTaskField,
	},
	{
		key: 'categories',
		type: 'categories',
		label: 'Tags',
		description: 'Add tags to an entity',
		categories: ['General', 'Misc'],
		icon: faTags,
		component: TagsField,
		parse: parseTagsField,
	},
	{
		key: 'slider',
		type: 'slider',
		label: 'Slider',
		description: '0-100 Range slider',
		categories: ['General', 'Number'],
		icon: faSlider,
		component: SliderField,
		parse: parseSliderField,
	},
	{
		key: 'toggle',
		type: 'toggle',
		label: 'Toggle',
		description: 'On/Off toggle',
		categories: ['General', 'Toggle'],
		icon: faToggleOff,
		component: ToggleField,
		parse: parseToggleField,
	},
	{
		key: 'party',
		type: 'party',
		label: 'Party',
		description: 'Contract party',
		categories: ['General', 'Misc'],
		icon: faPartyHorn,
		component: PartyField,
	},
];
