import { GenericDateRangePicker } from '@/shared/components/fields/GenericDateRangePicker';
import { InfoItem } from '@/shared/components/items/InfoItem';
import dayjs, { Dayjs } from 'dayjs';
import { IFieldProps } from '../..';

export const DateRangeField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const dates = Array.isArray(value) ? value : [value, value];
	const parsedDates = parseDateRangeField(dates);
	const description = parsedDates?.map?.((d) => dayjs(d).format(`DD MMM YYYY ${field?.showtime ? 'HH:mm' : ''}`)).join(' - ');
	const hasDates = parsedDates?.some?.((d) => !!d);
	const handleChange = (dates: Dayjs[]) => {
		if (!dates || !dates?.length) {
			onChange([]);
			return;
		}
		onChange(dates?.map((d) => d?.toISOString()));
	};

	if (readOnly) {
		return <InfoItem title={field.label} description={hasDates ? description : 'No dates selected'} />;
	}

	return (
		<GenericDateRangePicker
			{...field}
			className="field-input"
			placeholder={[field?.placeholder, field?.placeholder]}
			value={parsedDates}
			onChange={handleChange}
		/>
	);
};

export const parseDateRangeField = (value: any) => {
	if (!Array.isArray(value) || value?.length !== 2 || value.some((v) => typeof v !== 'string' && !(v instanceof Date))) {
		return [] as any;
	}
	return value;
};
