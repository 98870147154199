import { AgreementLength } from '@/shared/components/fields';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { faRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs, { Dayjs } from 'dayjs';
import { Fragment } from 'react';
import { IFieldProps } from '../..';

export const MultiDateRangeField = ({ value, onChange, field, readOnly }: IFieldProps) => {
	const parsedValue = parseMultiDateRangeField(value);

	const handleChange = (dates: Dayjs[][]) => {
		if (!dates || !dates?.[0]) {
			onChange([]);
			return;
		}
		onChange(dates?.map?.(([start, end]) => [start?.toISOString(), end?.toISOString()]));
	};

	if (readOnly) {
		return (
			<div className="flex flex-col gap-2">
				{(!parsedValue || !parsedValue?.length || parsedValue === 'Invalid value') && (
					<InfoItem title={field.label} description={parsedValue === 'Invalid value' ? parsedValue : `No dates selected`} />
				)}
				{parsedValue?.map?.(([start, end], index: number) => {
					const startDate = dayjs(start).format('DD MMM YYYY');
					const endDate = dayjs(end).format('DD MMM YYYY');
					return (
						<Fragment key={index}>
							{index === 0 ? (
								<InfoItem title={field.label} description={`${startDate} - ${endDate}`} />
							) : (
								<div className="flex items-center gap-4 px-2">
									<FontAwesomeIcon className="text-gray-500" icon={faRotate} />
									<p className="text-xs font-medium">{`${startDate} - ${endDate}`}</p>
								</div>
							)}
						</Fragment>
					);
				})}
			</div>
		);
	}

	return (
		<AgreementLength
			{...field}
			placeholder={[field?.placeholder, field?.placeholder]}
			value={value?.map?.(([start, end]) => [dayjs(start), dayjs(end)])}
			onChange={handleChange}
		/>
	);
};

export const parseMultiDateRangeField = (value: any) => {
	if (!Array.isArray(value) && !Array.isArray(value?.[0]) && value?.every((v) => Array.isArray(v))) {
		return 'Invalid value';
	}
	return value;
};
