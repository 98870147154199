import { AddTagButton } from '@/features/tags/components/AddTagButton';
import { TagsViewer } from '@/features/tags/components/TagsViewer';
import { InfoItem } from '@/shared/components/items/InfoItem';
import { IFieldProps } from '../..';

export const TagsField = ({ value, onChange, field, entry, readOnly }: IFieldProps) => {
	const parsedTags = parseTagsField(value);

	if (readOnly) {
		return <InfoItem title={field.label} description={<TagsViewer tags={parsedTags} shownAmount={3} />} />;
	}

	return (
		<div>
			<p className="mb-1 text-xs font-medium text-gray-500">{field.label}</p>
			<div className="flex items-center gap-2">
				{!readOnly && <AddTagButton source={entry.uid} onSelect={(tag) => onChange([...parsedTags, tag])} />}
				<TagsViewer
					shownAmount={3}
					tags={parsedTags}
					source={entry.uid}
					onRemove={(tag) => {
						const filteredTags = value?.filter((t) => t.uid !== tag);
						onChange(filteredTags);
					}}
				/>
			</div>
		</div>
	);
};

export const parseTagsField = (value: any) => {
	if (!value || !Array.isArray(value) || value?.every?.((tag) => !tag?.name)) {
		return [];
	}
	return value;
};
